.Link{
    color:black;
    margin-right: 1rem;
    text-decoration: none;
    font-size: large;
    font-weight: 600;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .Link:hover{
    color: rgb(0, 81, 255);
  }
  .para{
    height: 72px;
  }
